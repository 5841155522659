// Libs
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// Utils
import { lsHandler } from "utils/libs";
// Actions
import { mutateDirectProps } from "components/containers/Dashboard/actions";
// Selectors
import { selectLinked } from "components/containers/Dashboard/selectors";

const useLinked = () => {
  const dispatch = useDispatch();
  const linked = useSelector(selectLinked);

  const syncLink = () => {
    if (!!Object.keys(linked).length) return;

    const savedLinked = lsHandler.getLinked();
    if (!savedLinked) return;

    dispatch(mutateDirectProps({ linked: savedLinked }));
  };

  useEffect(() => {
    syncLink();
  }, [linked]);

  return { linked };
};
export default useLinked;
