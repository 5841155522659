//Libs
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// Actions
import { sendToast } from "components/containers/Dashboard/actions";
//Hooks
import { useTheme } from "styled-components";
import { useUserManager } from "hooks";
//Components
import {
  Wrapper,
  Title,
  Select,
  LogoIcon,
  Avatar,
  Button,
  Caption,
} from "components/components";
import Input from "@material-ui/core/Input";
// Utils
import { CloudStorage } from "components/AppConfig.json";

const { Option } = Select;
const EntitySelectorAndJobApplicationRequest = ({ userId, isModalOpen }) => {
  const {
    getEntityAssignment,
    setUserProfileActive,
    sendJobApplicationRequest,
  } = useUserManager();
  const dispatch = useDispatch();
  const isMounted = useRef();

  const [state, _setState] = useState({
    entityAssignment: [],
    isLoading: false,
    isOpen: false,
    fieldValue: "",
  });

  const setState = (newData) =>
    _setState((current) => ({ ...current, ...newData }));

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setState({ isLoading: true });
    getEntityAssignment(userId)
      .then((entityAssignment) => {
        if (isMounted.current)
          setState({
            entityAssignment,
            isLoading: false,
          });
      })
      .catch(({ message, type }) => {
        if (!isMounted.current) return;
        dispatch(
          sendToast({
            message:
              message ||
              "Tuve un problema al realizar la consulta de los datos. Por favor inténtalo más tarde",
            type: type || "warn",
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (!isModalOpen) setState({ fieldValue: "" });
  }, [isModalOpen]);

  if (!state.entityAssignment.length) return null;

  const currentEntity = state.entityAssignment.find(
    (entity) => !!entity.selected
  );

  const onSetUserProfileActive = (entityId) => {
    setUserProfileActive({ userId, entityId }).then(() => {
      window.location.reload();
    });
  };

  const onChangeInputValue = (event) => {
    setState({ fieldValue: event.target.value });
  };

  const onSendJobApplication = (entityCode) => {
    setState({ isLoading: true });
    sendJobApplicationRequest(userId, entityCode)
      .then(() => {
        if (!isMounted.current) return;

        setState({ fieldValue: "", isLoading: false });
        dispatch(
          sendToast({
            message:
              "¡Solicitud enviada! Por favor, revise su bandeja de notificaciones para conocer su estado.",
            type: "success",
          })
        );
      })
      .catch(({ message, type }) => {
        if (!isMounted.current) return;

        setState({ isLoading: false });
        dispatch(
          sendToast({
            message: message,
            type: type || "error",
          })
        );
      });
  };

  return (
    <Wrapper
      width="100%"
      flexDirection="column"
      height="195px"
      alignItems="stretch"
    >
      <div style={{ display: "grid", gap: "10px" }}>
        <Caption>Empresa actual</Caption>
        <Select
          width="100%"
          value={
            !state.isOpen || state.isLoading
              ? currentEntity.name
              : currentEntity.id
          }
          onChange={onSetUserProfileActive}
          onDropdownVisibleChange={(visible) => setState({ isOpen: visible })}
          dropdownRender={(menu) => (
            <>
              {menu}
              {state.isLoading && (
                <Wrapper width="100%" justifyContent="center">
                  <LogoIcon spin={true} />
                </Wrapper>
              )}
            </>
          )}
        >
          {state.entityAssignment.map((entity) => (
            <Option key={entity.id} value={entity.id}>
              {entity.name}
            </Option>
          ))}
        </Select>
        <Caption>Solicitar trabajo en la empresa</Caption>
        <Input
          disableUnderline={true}
          placeholder="Código*"
          onChange={onChangeInputValue}
          value={state.fieldValue}
          type="text"
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            padding: "1px 10px 1px",
          }}
        />
        <Wrapper width="100%" justifyContent="center">
          {state.isLoading ? (
            <LogoIcon spin={true} />
          ) : (
            <Button
              mode="primary"
              onClick={() => {
                state.fieldValue && onSendJobApplication(state.fieldValue);
              }}
              disabled={!state.fieldValue}
            >
              Enviar solicitud
            </Button>
          )}
        </Wrapper>
      </div>
    </Wrapper>
  );
};

export default function UserProfileData(props) {
  const { userId, userName, isModalOpen } = props;
  const theme = useTheme();

  return (
    <Wrapper
      width="100%"
      padding="0"
      margin="0"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={theme.colors.primary}
      overflow="auto"
    >
      <Wrapper
        width="100%"
        gap="15px"
        margin="0"
        justifyContent="center"
        flexDirection="column"
      >
        <Avatar
          fit
          avatar={{
            src: CloudStorage.concat(
              `/syn4pse/users/avatars/${
                userId ? `${userId}.jpg` : `default.svg`
              }`
            ),
          }}
        />

        <Title>{userName}</Title>

        <Wrapper
          padding="0 16px"
          width="70%"
          gap="5px"
          flexDirection="column"
          justifyContent="center"
        >
          <EntitySelectorAndJobApplicationRequest
            userId={userId}
            isModalOpen={isModalOpen}
          />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
}
