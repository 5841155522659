//Libs
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
//Components
import { Link } from "react-router-dom";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Wrapper,
  Imagotype,
  OnboardMainButton,
  Subtitle,
} from "components/components";
//Utils
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
//Actions
import * as actions from "./actions";

//Styles
import "./index.css";
const styles = (theme) => ({
  textField: {
    width: "200px",
    minHeight: "48px",
    height: "48px",
    paddingLeft: "15px",
    backgroundColor: "#E8E8E8",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: "10px",
    fontFamily: "Raleway",
  },
  icon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
});

const { ENV } = GENERAL;

class Signup extends Component {
  constructor(...props) {
    super(...props);

    this.Auth = new AuthService();
  }

  /******************** LIFECYCLES ********************/
  componentWillMount() {
    if (this.Auth.loggedIn()) {
      return <Redirect to="/" />;
    }
  }

  componentWillUnmount() {
    this.props.doReset();
  }
  /******************** JSX RENDER ********************/
  render() {
    const {
      classes,
      idc,
      name,
      phone,
      email,
      password,
      entityCode,
      status,
      loading,
      showPassword,
      mutateDirectProps,
      doSignup,
    } = this.props;
    return (
      <Wrapper
        className="animated fadeIn"
        padding="0"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Wrapper
          position="relative"
          display="flex"
          flexDirection="column"
          padding="4em 1.5em 0em 1.5em"
          maxWidth="370px"
          gap="15px"
          height="500px"
          borderRadius="50px"
          boxShadow="0 8px 16px 0 rgb(0 0 0)"
        >
          {/* LOGO */}
          <Imagotype position="absolute" top="-55px" opacity=".7" />
          <div className="container-signup-form-action-inputs">
            {/* IDENTIFICATION */}
            <Input
              name="idc"
              required
              placeholder="Identidad*"
              className={classes.textField}
              onChange={(e) =>
                mutateDirectProps({ idc: e.target.value?.trim() })
              }
              value={idc}
              type="text"
            />

            {/* NAME */}
            <Input
              required
              name="name"
              placeholder="Nombre*"
              className={classes.textField}
              onChange={(e) => mutateDirectProps({ name: e.target.value })}
              value={name}
              type="text"
            />

            {/* PHONE */}
            <Input
              className={classes.textField}
              name="phone"
              type="number"
              placeholder="Teléfono*"
              value={phone}
              onChange={(e) =>
                mutateDirectProps({ phone: e.target.value?.trim() })
              }
            />
            {/* EMAIL */}
            <Input
              required
              className={classes.textField}
              name="email"
              type="text"
              placeholder="Correo*"
              value={email}
              onChange={(e) =>
                mutateDirectProps({ email: e.target.value?.trim() })
              }
            />
            {/* PASSWORD */}
            <Input
              required
              placeholder="Contraseña*"
              id="adornment-password"
              name="password"
              className={classes.textField}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) =>
                mutateDirectProps({ password: e.target.value?.trim() })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      mutateDirectProps({ showPassword: !showPassword })
                    }
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? (
                      <VisibilityOff className={classes.icon} />
                    ) : (
                      <Visibility className={classes.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />

            {/* ENTITY CODE */}
            <Input
              className={classes.textField}
              required
              name="entityCode"
              type="text"
              placeholder="Código empresa*"
              value={entityCode}
              onChange={(e) =>
                mutateDirectProps({ entityCode: e.target.value?.trim() })
              }
            />
          </div>

          {/* LOGIN-BUTTON */}
          <div className="container-signup-form-action-buttons">
            {loading ? (
              <div
                style={{
                  maxWidth: "800px",
                  marginTop: "15px",
                  minWidth: 24,
                  textAlign: "center",
                }}
              >
                <CircularProgress
                  variant="indeterminate"
                  size={24}
                  thickness={4}
                />
              </div>
            ) : (
              <div className="container-signup-form-action-buttons">
                {/* BUTTON */}
                <OnboardMainButton
                  variant={status === "error" ? "contained" : "outlined"}
                  color={status === "error" ? "secondary" : "primary"}
                  onClick={() => {
                    doSignup(idc, name, phone, email, password, entityCode);
                  }}
                >
                  Registrarme
                </OnboardMainButton>

                {/* LABEL */}
                <Wrapper
                  width="100%"
                  padding="0"
                  margin="10px 0 0 0"
                  justifyContent="space-around"
                >
                  <Subtitle>¿Ya tiene cuenta?</Subtitle>
                  <Link to={ENV.ROUTES.PATHS.LOGIN}>Ingresar</Link>
                </Wrapper>
              </div>
            )}
          </div>
        </Wrapper>
      </Wrapper>
    );
  }
}

/******************** PROPTYPES ********************/
Signup.propTypes = {
  //Props
  classes: PropTypes.object.isRequired,
  //Functions
  mutateDirectProps: PropTypes.func.isRequired,
  doSignup: PropTypes.func.isRequired,
  doReset: PropTypes.func.isRequired,
};

/******************** EXPORT ********************/
export default connect(
  (state) => ({
    idc: state.signup.idc,
    name: state.signup.name,
    phone: state.signup.phone,
    email: state.signup.email,
    password: state.signup.password,
    entityCode: state.signup.entityCode,
    status: state.signup.status,
    loading: state.signup.loading,
    showPassword: state.signup.showPassword,
  }),
  actions
)(withStyles(styles)(Signup));
