//Dependencies
import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import GENERAL from "utils/constants/general";
const { SIGNUP, DIVISION_ID } = GENERAL;

//Watcher Sagas
function* signupWatcher() {
  yield takeLatest(SIGNUP.SIGNUP, signupWorker);
}

//Worker Sagas
function* signupWorker(action) {
  const newUser = action.payload;

  try {
    yield put({ type: SIGNUP.MUTATE_DIRECT_PROPS, payload: { loading: true } });
    yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat("/users/signup"),
      { ...newUser, division_id: DIVISION_ID.EXP }
    );
    yield put({
      type: SIGNUP.TOAST_MESSAGE,
      payload: {
        message: "Registro completado!",
        type: "success",
        duration: 10,
      },
    });
    yield put({ type: SIGNUP.RESET_REGISTRY });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: SIGNUP.MUTATE_DIRECT_PROPS,
          payload: { loading: false, status: "error" },
        });
      },
      function* () {
        yield signupWorker(action);
      }
    );
  }
}

//  Export default Root Saga
export default function* rootSaga() {
  yield all([signupWatcher()]);
}
