import { ApiServiceV2 } from "services";

// Services

const useUserManager = () => {
  return {
    //Set on way order
    recoveryPasswordRequest: (idc) =>
      ApiServiceV2.post({
        url: `/users/recovery_password_request/${idc}`,
      }),
    recoveryPasswordConfirm: (idc, recoveryCode) =>
      ApiServiceV2.put({
        url: `/users/recovery_password_confirm/${idc}/${recoveryCode}`,
      }),
    recoveryPasswordUpdate: (idc, newPassword) =>
      ApiServiceV2.put({
        url: `/users/recovery_password_update/${idc}/${newPassword}`,
      }),
    getEntityAssignment: (userId) =>
      ApiServiceV2.get({
        url: `users/getEntityAssignment/${userId}`,
      }),
    setUserProfileActive: ({ userId, entityId }) =>
      ApiServiceV2.put({
        url: `users/setUserProfileActive`,
        data: { userId, entityId },
      }),
    sendJobApplicationRequest: (userId, entityCode) =>
      ApiServiceV2.post({
        url: `users/jobApplicationRequest/${userId}/${entityCode}`,
      }),
  };
};
export default useUserManager;
