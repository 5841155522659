import GENERAL from "../../../utils/constants/general";
import { notification } from "antd";

const { SIGNUP } = GENERAL;

const initialState = {
  idc: undefined,
  name: undefined,
  phone: undefined,
  email: undefined,
  password: undefined,
  entityCode: undefined,
  showPassword: false,
  loading: false,
  status: "register", //register, error
  toastMessage: {
    message: "Neurobot", //Neurobot, Synbot
    description: "",
    duration: 5,
    type: "info",
    className: "notification-dark-style",
  },
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case SIGNUP.RESET_REGISTRY: {
      return {
        ...state,
        idc: "",
        name: "",
        phone: "",
        email: "",
        password: "",
        showPassword: false,
        loading: false,
        status: "register",
      };
    }
    case SIGNUP.TOAST_MESSAGE: {
      const { message, description, duration, type } = action.payload,
        toastMessage = {
          ...state.toastMessage,
          message: message ? message : state.toastMessage.message,
          description: description
            ? description
            : state.toastMessage.description,
          duration: duration ? duration : state.toastMessage.duration,
          type: type ? type : state.toastMessage.type,
        };

      switch (type) {
        case "error":
          notification.error({ ...toastMessage });
          break;
        case "warn":
          notification.warn({ ...toastMessage });
          break;
        case "warning":
          notification.warning({ ...toastMessage });
          break;
        case "success":
          notification.success({ ...toastMessage });
          break;
        default:
          notification.info({ ...toastMessage });
          break;
      }

      return {
        ...state,
      };
    }
    case SIGNUP.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default loginReducer;
