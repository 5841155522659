import React from "react";
import { useTheme } from "styled-components";

const Direction = () => {
  const theme = useTheme();
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7101 10.2901L10.7101 1.29006C10.3201 0.900059 9.69007 0.900059 9.30007 1.29006L0.300068 10.2901C-0.0899316 10.6801 -0.0899316 11.3101 0.300068 11.7001L9.30007 20.7001C9.69007 21.0901 10.3201 21.0901 10.7101 20.7001L19.7101 11.7001C20.1001 11.3201 20.1001 10.6901 19.7101 10.2901ZM12.0001 13.5001V11.0001H8.00007V14.0001H6.00007V10.0001C6.00007 9.45006 6.45007 9.00006 7.00007 9.00006H12.0001V6.50006L15.5001 10.0001L12.0001 13.5001Z"
        fill={theme.fonts.colors.default}
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default Direction;
